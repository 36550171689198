
import CloseButton from "./CloseButton";
import ToggleButton from "./ToggleButton";

export default {
  name: "Navbar",
  components: {
    CloseButton,
    ToggleButton,
  },
  data() {
    return {
      toggled: false,
    };
  },
  methods: {
    toggleMenu() {
      this.toggled = !this.toggled;
    },
  },
};
