
export default {
  name: "ToggleButton",
  props: {
    target: {
      type: [String, Number],
      description: "Toggle button target element",
      required: true,
    },
    expanded: {
      type: Boolean,
      description: "Whether button is expanded (aria-expanded attribute)",
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
